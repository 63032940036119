import React from "react";
import "../css/main.css";
import Layout from "../components/Layout/Layout";
import { Helmet } from "react-helmet";
import { Provider } from 'react-redux';
import configureStore from "../store";
import "@dnb/eufemia/style/core";
import "@dnb/eufemia/style/components";
import "@dnb/eufemia/style/themes/ui";
import OrderServices from "../components/OrderServices/OrderServices";
import { ErrorBoundary } from "../components/Common/ErrorBoundary";

const store = configureStore();
const IndexPage = () => (
    <Provider store={store}>
        <Helmet>
            <title>Koble sammen nettbanken med ditt ERP-/regnskapssystem - DNB</title>
        </Helmet>
        <ErrorBoundary>
            <Layout>
                <OrderServices path="/" />
            </Layout>
        </ErrorBoundary>
    </Provider>
);

export default IndexPage;
